import isServer from '@lyra/core/utils/isServer'
import NextVideoLink from '@lyra/web/components/common/NextVideoLink'
import useBoolLocalStorage from '@lyra/web/hooks/local_storage/useBoolLocalStorage'
import useSelectedTab from '@lyra/web/hooks/useSelectedTab'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'
import { useTheme } from 'tamagui'

import { getTabPageIdForPath, TabDetails, TabId, TabPage } from '../../../../utils/tabs'

const NavDesktopTabPageLink = ({ page }: { page: TabPage }) => {
  const pathname = usePathname()
  const activePage = useMemo(() => getTabPageIdForPath(pathname), [pathname])

  return (
    <NextVideoLink
      key={page.name}
      href={page.path}
      label={page.name}
      isActive={activePage === page.id}
      size="md"
    />
  )
}

const NavDesktopHeaderTabLink = ({ tab, usePages }: { tab: TabDetails; usePages: boolean }) => {
  const selectedTab = useSelectedTab()

  const isAirdropTab = tab.id === TabId.Airdrop

  const theme = useTheme()

  const [isAirdropOpened, setIsAirdropOpened] = useBoolLocalStorage('airdrop-tab-nudge')

  return usePages ? (
    <>
      {tab.pages.map((page) => (
        <NavDesktopTabPageLink page={page} key={page.name} />
      ))}
    </>
  ) : (
    <NextVideoLink
      key={tab.id}
      href={tab.pages[0].path}
      label={tab.name}
      isActive={selectedTab && selectedTab.id === tab.id}
      size="md"
      onPress={() => setIsAirdropOpened(true)}
      style={
        isAirdropTab && selectedTab?.id !== TabId.Airdrop && !isAirdropOpened && !isServer
          ? {
              background: `linear-gradient(${theme.red?.get()}, ${theme.amber?.get()})`,
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }
          : null
      }
    />
  )
}

export default NavDesktopHeaderTabLink
