import Button from '@lyra/core/components/Button'
import { AlertTriangle, Check, Copy, PowerOff, Settings, X } from '@lyra/core/components/Icon'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import formatTruncatedEmail from '@lyra/core/utils/formatTruncatedEmail'
import NavDropdownButton from '@lyra/web/components/common/NavDropdownButton'
import { PageId } from '@lyra/web/constants/pages'
import useAuth from '@lyra/web/hooks/useAuth'
import useToast from '@lyra/web/hooks/useToast'
import { getPagePath } from '@lyra/web/utils/pages'
import { useRouter } from 'next/navigation'
import React, { useCallback, useState } from 'react'

import SignInButton from '../SignInButton'

const NavAccountDropdownButton = () => {
  const router = useRouter()

  const { isAuthenticated, isAuthenticating, user, session, error, logout } = useAuth()

  const { createToast } = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const handleCopyAddress = useCallback(() => {
    if (!user?.ownerAddress) {
      return
    }
    navigator.clipboard.writeText(user.ownerAddress)
    createToast({
      title: 'Copied Address',
      description: `Copied ${formatTruncatedAddress(user.ownerAddress)} to clipboard`,
      icon: <Check />,
      color: 'green',
    })
  }, [user?.ownerAddress, createToast])

  if (isAuthenticating) {
    return <Button isLoading />
  }

  if (!isAuthenticated) {
    if (session && error) {
      // something went wrong during authentication, user should retry createSession
      return (
        <NavDropdownButton icon={<AlertTriangle />} label="Error" color="amber">
          <NavDropdownButton.ListItem
            label="Cancel"
            sublabel="Cancel sign in attempt"
            onPress={logout}
            rightIcon={<X />}
          />
        </NavDropdownButton>
      )
    }
    return <SignInButton />
  }

  const username = user.username
    ? user.username
    : session.email
    ? formatTruncatedEmail(session.email)
    : formatTruncatedAddress(user.ownerAddress)

  return (
    <NavDropdownButton label={username} isLoading={isLoading}>
      <NavDropdownButton.ListItem
        label="COPY ADDRESS"
        sublabel={formatTruncatedAddress(user.ownerAddress)}
        rightIcon={<Copy />}
        onPress={handleCopyAddress}
      />
      <NavDropdownButton.ListItem
        label="SETTINGS"
        rightIcon={<Settings />}
        onPress={() => router.push(getPagePath({ page: PageId.Settings }))}
      />
      <NavDropdownButton.ListItem
        label="Disconnect"
        rightIcon={<PowerOff />}
        onPress={async () => {
          setIsLoading(true)
          await logout()
          setIsLoading(false)
        }}
      />
    </NavDropdownButton>
  )
}

export default NavAccountDropdownButton
